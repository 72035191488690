import { View } from '../manager';
import '../globals';
import './reducer';
import React, { createElement as h } from 'react';
import Timeline from '../model/timeline';
import s from '../system';
import { IAppState } from 'vev';
import { pageKeyByPath } from '../utils/route';
import polyfills from './polyfills';
import { StateProvider } from './state';
import { App } from '../components';
import ReactDOM from 'react-dom';

export { Timeline };

export * from './state';
export * from './hooks';
export * from '../manager';
export * from '../components';
export * from '../utils';

export { s, h };

function importAll(state: IAppState): Promise<any> {
  const imports: { [type: string]: boolean } = {};
  for (const { type } of state.models) {
    if (type !== undefined) {
      imports[state.pkg[type] || type] = true;
    }
  }
  return Promise.all(Object.keys(imports).map((key) => s.import(key, true)));
}

export async function render(
  state: IAppState,
  root: HTMLElement = document.getElementById(state.project || '') as HTMLElement,
  doHydrate = true,
) {
  if (!root) return console.error('Failed to render vev, missing root node');

  View.updateSize();
  await Promise.all([importAll(state), polyfills()]);

  // state.embed = root.hasAttribute('embed');
  if (!state.route) {
    // const pageKey = state.embed
    //   ? root.getAttribute('embed') || state.pages.find((p) => p.index).key
    //   : pageKeyByPath(location.pathname, state.pages, state.dir);
    state.route = { pageKey: pageKeyByPath(location.pathname, state.pages, state.dir) };
  }

  const jsx = (
    <StateProvider state={state}>
      <App />
    </StateProvider>
  );

  if (doHydrate) ReactDOM.hydrate(jsx, root);
  else ReactDOM.render(jsx, root);
}

declare const vevr: () => void;
declare const vevc: () => void;
// Check if pending vev widget register exits (window.vevr). Added by widget script if loaded before window.vev exists
if (typeof vevr !== 'undefined') setTimeout(vevr, 0);
// Check if pending vev content render exits (window.vevr). Added by content script if loaded before window.vev exists
if (typeof vevc !== 'undefined') setTimeout(vevc, 0);

export const Router = {};
