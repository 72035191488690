import { IPage } from 'vev';

const DOUBLE_SLASH = /\/{2,}/g;

export function pageKeyByPath(path = '', pages: IPage[], dir?: string): string {
  path = path.replace(new RegExp('^\\/?' + cleanPath(dir || '')), '');
  path = removeUnnecessarySlashes(cleanPath(path));

  const indexPage = pages.find((page) => page.index) || pages[0];
  if (!path || path === '/' || path === cleanPath(dir || '')) return indexPage.key;
  for (const page of pages) {
    if (path.replace(/^\//, '') === page.key) return page.key;
    // Skip index page because it's the fallback if no match
    if (page.index) continue;
    // Finding start index in path for the page path (may be none (-1)), also checking if path is sub string of path
    const startIndex = path.indexOf(page.path);
    const pagePath = removeUnnecessarySlashes(page.path);
    // If page path is substring
    // then we check if they're a perfect match  extracting substring of the path
    if (startIndex !== -1 && path === pagePath) {
      return page.key;
    }
  }
  return indexPage.key;
}

const removeUnnecessarySlashes = (path: string) => path.replace(/(^\/)|(\/$)/g, '');

export const join = (...path: string[]) => (path.join('/') + '/').replace(DOUBLE_SLASH, '/');

export function cleanPath(path: string | undefined) {
  return !path
    ? ''
    : path
        // remove starting .
        .replace(/^\./, '')
        // Remove query >azløkjbhvgcx>Zogfd8 or hash
        .replace(/(\?|#).*$/, '')
        // Remove double slash
        .replace(DOUBLE_SLASH, '/')
        // Remove trailing slash (but not if it's starting slash (path = "/"))
        .replace(/(?!^\/)\/$/, '')
        .replace(/index.html$/i, '');
}

/**
 *  Get page path by key
 */
export function pagePathByKey(pageKey: string, pages: IPage[], dir?: string): string {
  const page = pages.find((page) => page.key === pageKey);
  return page ? cleanPath(join('/', cleanPath(dir), page.path)) : '';
}
